<template>
  <div class="py-4 h-100 container-fluid">
    <div class="row mt-3">
      <div class="text-center col-12">
        <h3>Easy Social - Hochladen</h3>
        <div class="modal fade pe-4" id="exampleModal" tabindex="-1"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true"
             v-if="noCategories">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header d-flex row" style="margin-left: 5px; margin-right:5px">
                <h1 class="modal-title fs-5 text-wrap" id="exampleModalLabel">Neue Kategorie erstellen</h1>
              </div>
              <div class="modal-body">
                <label>Neue Kategorie</label>
                <soft-input placeholder="" v-model="firstCategory" id="name"></soft-input>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn" id="closebtn"
                        style="color:white; background-color: #333"
                        data-bs-dismiss="modal" @click="clear()">Abbrechen
                </button>
                <button type="button" class="btn btn-primary" @click="newCategory">Erstellen</button>
              </div>
            </div>
          </div>
        </div>
        <h5 class="font-weight-normal">
          Erstelle ganz einfach Posts mit Bildern, Text und Hashtags!
        </h5>
        <div class="mb-4 multisteps-form">
          <!--progress bar-->
          <div class="row">
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-12 col-lg-10">
              <form class="multisteps-form__form" @submit.prevent>
                <div :class="activeClass" class="p-3 card">
                  <h5 class="font-weight-bolder">Hochladen</h5>
                    <add-image stage="uploader" :status=false :added-images="resultList.length > 0 ? resultList : []" @update:list="updateList"></add-image>
                  <label for="textFormInput" class="form-label">Beschreibe um was es sich in deinem Beitrag
                    handelt </label>
                  <div class="row pb-3">
                    <div class="col-auto">
                      <div>
                        <div class="card-body d-flex flex-column justify-content-sm-evenly p-0 mt-3"
                             style="max-height: 43rem" :style="resultList.length === 0 ? 'display:none !important' : ''">
                          <img v-if="checkForImage(resultList[uploadSlider]) && resultList.length > 0"
                               class="border-radius-lg"
                               style="max-height: 30rem; object-fit: contain; max-width: 20rem;"
                               :src="resultList[uploadSlider]['base64Image']"
                               alt="product_image"
                          />
                          <div v-if="!checkForImage(resultList[uploadSlider])">
                            <video style="max-height: 30rem; object-fit: contain; max-width: 20rem"
                                   :src="resultList[uploadSlider]['base64Image']"
                                   muted autoplay controls class="col-12 mb-3"></video>
                          </div>
                          <div style="display: flex; justify-content: space-between;">
                            <soft-button @click="uploadSlider--" :style="uploadSlider > 0 ? 'visibility: visible' : 'visibility: hidden'"
                                         style="margin-top: 10px;"
                            >
                              <i class="fa fa-arrow-left"></i>
                            </soft-button>
                            <div class="d-flex gap-2" >
                              <label for="checkbox" class="m-0 d-flex align-items-center fs-6 cursor-pointer">
                                Bild entfernen:
                              </label>
                              <input class="cursor-pointer" type="checkbox" name="checkbox" id="checkbox"
                                     :value="resultList[uploadSlider]" v-model="deleteImages"/>
                            </div>
                            <soft-button :style="uploadSlider < resultList.length-1 ? 'visibility: visible' : 'visibility: hidden'" @click="uploadSlider++"
                                         style="margin-top: 10px;">
                              <i class="fa fa-arrow-right"></i>
                            </soft-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col p-3 pb-4">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <div class="mb-3">
                              <textarea class="form-control" id="textFormInput" rows="5" maxlength="1000"
                                        v-model="postText"></textarea>
                          </div>
                        </div>
                        <div class="col-12 mb-3">
                          <div class="mb-3">
                            <label for="hashtagFormInput" class="form-label">Hashtags</label>
                            <textarea class="form-control" id="hashtagFormInput" rows="3" maxlength="1000"
                                      v-model="postHashtags"></textarea>
                          </div>
                        </div>
                        <div class="col-12 mb-3">
                          <div class="mb-3">
                            <label for="hashtagFormInput" class="form-label">Zusatzinformation</label>
                            <textarea class="form-control" id="hashtagFormInput" rows="3" maxlength="1000"
                                      v-model="additionalInfoText"></textarea>
                          </div>
                        </div>
                        <div class="col-12 mb-3">
                          <label class="form-label">Kategorie</label>
                          <select
                              id="category-Select"
                              class="form-control m-auto"
                              name="categorySelect"
                              v-model="category">
                            >
                          </select>
                        </div>
                        <div class="col-6 mb-3">
                          <label>Zeitpunkt</label>
                          <soft-input
                              id="zeitpunkt"
                              v-model="time"
                              dataformatas="HH:mm"
                              filled
                              type="time"
                              hint="Zeitpunkt der Veröffentlichung"
                          />
                        </div>
                        <div class="col-6 mb-3">
                          <label>Datum</label>
                          <soft-input
                              id="datum"
                              v-model="date"
                              filled
                              type="date"
                              hint="Datum der Veröffentlichung"
                          />
                        </div>
                        <div class="d-flex align-items-center flex-column">
                          <div class="form-label mb-2">Social-Media-Plattform (min. 1)</div>
                          <div class="gap-3 d-flex flex-wrap" id="social-platform">
                            <button type="button" style="border:1px" :style="instagramStyle" class="badge badge-lg"
                                    @click="instagram = !instagram">
                              <font-awesome-icon :icon="['fab', 'instagram']" size="lg"/>
                            </button>
                            <button type="button" style="border:1px" :style="facebookStyle" class="badge badge-lg"
                                    @click="facebook = !facebook">
                              <font-awesome-icon :icon="['fab', 'facebook']" size="lg"/>
                            </button>
                            <button type="button" style="border:1px" :style="linkedinStyle" class="badge badge-lg"
                                    @click="linkedin = !linkedin">
                              <font-awesome-icon :icon="['fab', 'linkedin']" size="lg"/>
                            </button>
                          </div>
                          <div v-if="socialMediaErrorMessage" class="text-danger d-flex align-items-center mt-2">
                            Bitte wählen Sie mindestens eine Social-Media-Platform
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex button-row">
                      <button
                          class="mb-0 btn bg-primary ms-auto js-btn-next"
                          type="button"
                          title="Hochladen"
                          @click="upload"
                      >
                        Hochladen
                      </button>
                    </div>
                  </div>
                </div>
                <!--single form panel-->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone";

import SinglePostView from "./SinglePostView.vue";
import api, {apiGet, apiPost} from "@/api/api";
import {Cropper} from "vue-advanced-cropper";
import VueCropper from "@/components/VueCropper.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import * as Choices from "choices.js";
import {ref} from "vue";
import AddImage from "@/views/easy-social/AddImage.vue";

export default {
  name: "Uploader",
  components: {AddImage, SoftInput, SoftButton, VueCropper, Cropper, SinglePostView},

  data() {
    return {
      deleteImages: [],
      activeClass: "js-active position-relative",
      formSteps: 0,
      message: "",
      date: new Date().toISOString().slice(0, 10),
      time: "18:00",
      imageCropListData: [],
      postText: '',
      category: '',
      postHashtags: '',
      choicesInstants: null,
      postUsage: 'Beitrag',
      additionalInfoText: '',
      instagram: false,
      facebook: false,
      linkedin: false,
      restrictScrollEvent: 1,
      socialMediaErrorMessage: false,
      noCategories: false,
      firstCategory: '',
      resultList: [],
      uploadSlider: 0,
    };
  },
  async mounted() {
    this.$store.state.showNavbarMini = false;
    this.$store.state.isAbsolute = true;
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    sidenav_show.children[0].style.setProperty("background-color", "white", "important");

    if (this.$store.state.activeId === undefined || this.$store.state.activeId === null) {
      this.$store.state.activeId = localStorage.getItem('companyId')
    }
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
    const categories = await apiGet(localStorage.getItem('adminId'), '/company/categories?companyId=' + this.$store.state.activeId)
    this.categories = categories.data.map((x) => {
      return x.category
    })
    if (this.categories.length === 0) {
      this.noCategories = true
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    instagramStyle() {
      return this.instagram ? 'color: white; background-color: #e91e63' : 'color: grey'
    },
    facebookStyle() {
      return this.facebook ? 'color: white; background-color: #1a237e' : 'color: grey'
    },
    linkedinStyle() {
      return this.linkedin ? 'color: white; background-color: #2196f3' : 'color: grey'
    },
    validateSocialMedia() {
      return this.instagram || this.facebook || this.linkedin
    },
    uploadData() {
      let media = this.resultList.map((x) => {
        let val = x
        if (typeof x === 'object') {
          val = x['base64Image']
        }
        return {
          base64Image: val
        }
      })
      let removeList = this.deleteImages.map((x) => {
        let val = x
        if (typeof x === 'object') {
          val = x['base64Image']
        }
        return {
          base64Image: val
        }
      })
      media = media.filter(mediaItem => !removeList.some(deleteItem => deleteItem.base64Image === mediaItem.base64Image));
      return {
        images: media,
        text: encodeURI(this.postText.trim()),
        hashtags: encodeURI(this.postHashtags.trim()),
        additionalInfo: encodeURI(this.additionalInfoText.trim()),
        usage: this.postUsage,
        category: this.category,
        instagramText: this.instagram ? 'true' : '',
        facebookText: this.facebook ? 'true' : '',
        linkedinText: this.linkedin ? 'true' : '',
        publicationDate: new Date(this.date + ' ' + this.time),
        statusId: 1,
      }
    }
  },
  methods: {
    checkForImage(item) {
      let val = item
      if (typeof item === 'object') {
        val = item['base64Image']
      }
      return val !== undefined ? val.includes('image') : {'base64Image': ''}
    },
    clear() {
      this.firstCategory = ''
    },
    async newCategory() {
      if (this.firstCategory !== '') {
        this.noCategories = false
        let resp
        let companyId
        if (JSON.parse(localStorage.getItem('companyId'))) {
          companyId = JSON.parse(localStorage.getItem('companyId'));
        }
        if (JSON.parse(sessionStorage.getItem('companyId'))) {
          companyId = JSON.parse(sessionStorage.getItem('companyId'));
        }
        resp = await apiPost(companyId, '/company/newCategory?companyId=' + this.$store.state.activeId, {
          category: this.firstCategory
        }).then(async () => {
          const categories = await apiGet(localStorage.getItem('adminId'), '/company/categories?companyId=' + this.$store.state.activeId)
          this.categories = categories.data.map((x) => {
            return x.category
          })
        })
      }
    },

    async handleScroll() {
      if (this.$store.state.activeId === undefined || this.$store.state.activeId === null) {
        this.$store.state.activeId = localStorage.getItem('companyId')
      }
      if (this.restrictScrollEvent === 1) {
        if (document.getElementById("category-Select") && this.choicesInstants === null) {
          const status = document.getElementById("category-Select");
          this.choicesInstants = new Choices(status, {
            itemSelectText: '',
            searchEnabled: false,
          })

          const resp = await apiGet(localStorage.getItem('adminId'), '/company/categories?companyId=' + this.$store.state.activeId)
          const categoryOptions = []
          let category = null

          for (let i = 0; i < resp.data.length; i++) {
            category = {
              id: resp.data[i].id,
              name: resp.data[i].category,
              selected: i === 0
            }
            categoryOptions.push(category);
          }


          for (category in categoryOptions) {
            if (categoryOptions[category].selected === true) {
              this.category = categoryOptions[category].name
            }
          }

          this.choicesInstants.setChoices(categoryOptions,
              'name', 'name', false)
        }
        this.restrictScrollEvent = 2
      }
    },
    async upload() {
      this.socialMediaErrorMessage = false
      if (this.validateSocialMedia) {
        if (this.category !== '') {
          localStorage.setItem('companyId', this.$store.state.activeId)
          await api.post('/post/save?companyId=' + this.$store.state.activeId.toString(), this.uploadData).then(() => {
            this.$router.push({name: 'Posts'})
          })
        } else {
          this.noCategories = true;
        }
      } else {
        this.socialMediaErrorMessage = true;
      }
    },
    updateList(update) {
      if (update['images'])
      {
        this.resultList = update['images']; // Liste erfolgreich aktualisieren
      }
    }
  },
}
</script>
<style>
#exampleModal {
  top: 200px;
}

.modal-dialog {
  padding-left: 100px;
}

.twitter-cropper {
  height: 300px;

  &__background {
    background-color: #edf2f4;
  }

  &__foreground {
    background-color: #edf2f4;
  }

  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
</style>

